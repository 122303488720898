import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Form from "../components/molecules/form";
import Button from "../components/atoms/button";
import Input from "../components/atoms/input";

function DesignForm() {
  return (
    <div className="design__content">
      <SEO title="Design System - Form" />
      <h2>Form</h2>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Form</h5>
          <DesignStory
            type="component"
            component={Form}
            subComponent={[Input, Input, Button]}
            props={{
              onSubmit: (e) => {
                e.preventDefault();
                console.log("submit demo form");
              },
              label: "Demo Form",
              title: "Demo Form",
            }}
            subProps={[
              {
                placeholder: "Enter First Name",
                title: "First Name",
                type: "text",
                name: "firstName",
                id: "designFormFirstName",
                color: "green",
                value: "John",
              },
              {
                placeholder: "Enter Last Name",
                title: "Last Name",
                type: "text",
                name: "lastName",
                id: "designFormLastName",
                color: "green",
              },
              {
                color: "green",
                submit: true,
              },
            ]}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Form} title="Form Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignForm;
